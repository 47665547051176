import { ReactNode, useCallback, useEffect, useState } from 'react';
import copyImg from './assets/images/copy.png';
import { QRCodeSVG } from 'qrcode.react';
import errorImg from './assets/images/error.png';
import Loading from './Loading';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import './App.css';
import { getExqueryDomain } from 'repos/config';

enum STATUS {
	LOADING,
	SUCCESS,
	FAILED,
}
function App() {
	const [status, setStatus] = useState<STATUS>();
	const [data, setData] = useState<EXTERNALQUERY_API.QueryInvoiceReply>();
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const [modalTitle, setModalTitle] = useState<string>();
	const [modalContent, setModalContent] = useState<ReactNode>();
	const [modalBtnText, setModalBtnText] = useState<string>();
	const closeModal = () => {
		setModalIsOpen(false);
	};
	const openCopyedmodal = async () => {
		try {
			const params = new URLSearchParams(window.location.search);
			const param = params.get('param');
			if (!param) {
				throw new Error('param error');
			}
			axios.put(`${getExqueryDomain()}/query/v1/invoice/${param}`);
			setModalContent(
				<div className="flex">
					<img src={copyImg} alt="" />
					<b>Copiado com sucesso</b>
				</div>
			);
			setModalTitle('');
			setModalBtnText('ok');
			setModalIsOpen(true);
		} catch (error) {
			throw new Error('error');
		}
	};
	const openExpiredModal = () => {
		setModalTitle('dica');
		setModalContent(`O pedido expirou. Por favor, inicie o pagamento repetidamente.`);
		setModalBtnText('confirmação');
		setModalIsOpen(true);
	};
	const loadData = useCallback(async () => {
		setStatus(STATUS.LOADING);
		try {
			const params = new URLSearchParams(window.location.search);
			const param = params.get('param');
			if (!param) {
				throw new Error('param error');
			}
			const resp = await axios.get(`${getExqueryDomain()}/invoice/${param}`);
			if (resp?.data?.redirect) {
				window.location.replace(resp?.data?.redirect);
				return;
			}
			setData(resp.data);
			if (resp.data.status !== 2) {
				openExpiredModal();
			}
		} catch (e) {
			setStatus(STATUS.FAILED);
			return;
		}
		setStatus(STATUS.SUCCESS);
	}, []);

	useEffect(() => {
		if (status !== undefined) {
			return;
		}
		loadData();
		return () => {};
	}, [loadData, status]);

	if (status === STATUS.LOADING) {
		return (
			<div className="Loading">
				<Loading />
			</div>
		);
	}

	if (status !== STATUS.SUCCESS) {
		return (
			<div className="Loading">
				<img src={errorImg} alt="" />
				<div className="errorText">El enlace ha caducado, reinicie el pago</div>
			</div>
		);
	}
	return (
		<section>
			<div className="main">
				<div className="title">
					<div
						className="returnImg"
						onClick={() => {
							if (data?.returnUrl) {
								window.location.href = data?.returnUrl;
							} else {
								window.history.back();
							}
						}}
					></div>
				</div>
				<div className="titleText">Use o QR Code do Pix para pagar</div>
				<div className="main-content">
					{/* <div className="description">
						Use o app de seu banco ou carteira digital para escanear o código QR abaixo
					</div> */}
					<div className="codeBox">
						<QRCodeSVG size={188} value={data?.plain || ''} />
					</div>
					<p className="amount">{`R$ ${
						data?.orderAmount && (data?.orderAmount / 100).toFixed(2)
					}`}</p>
					<div className="expireAt">{`Expiração: ${data?.expireTime}`}</div>
				</div>
				<div className="footer">
					<CopyToClipboard text={data!.plain || ''} onCopy={openCopyedmodal}>
						<button className="button">COPIAR CÓDIGO PIX</button>
					</CopyToClipboard>
				</div>
				<div className="tips">
					<div>
						Abra o app com sua chave PIX cadastrada, escolha Pagar com Pix e escaneie o QR Code ou
						copie e cole o código.
					</div>
					<div>
						Este código QR só pode ser pago uma vez, se você precisar pagar novamente, solicite
						novamente COPIAR CÓDIGO PIX
					</div>
				</div>
			</div>
			{modalIsOpen && (
				<div className="modal">
					<div className="mask" onClick={closeModal} />
					<div className="modal-container">
						<section className="title">{modalTitle}</section>
						<section className="content">{modalContent}</section>
						<section className="footer">
							<button onClick={closeModal}>{modalBtnText}</button>
						</section>
					</div>
				</div>
			)}
		</section>
	);
}
export default App;
